import { render, staticRenderFns } from "./SectionIntroV2.vue?vue&type=template&id=6fe869d3&"
import script from "./SectionIntroV2.vue?vue&type=script&lang=js&"
export * from "./SectionIntroV2.vue?vue&type=script&lang=js&"
import style0 from "./SectionIntroV2.vue?vue&type=style&index=0&id=6fe869d3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,MoleculesCommonMoleculeSpecsList: require('/app/components/molecules/common/MoleculeSpecsList.vue').default,MoleculesCommonMoleculeAnchorButtons: require('/app/components/molecules/common/MoleculeAnchorButtons.vue').default,MoleculesCommonMoleculeCustomers: require('/app/components/molecules/common/MoleculeCustomers.vue').default,OrganismsCommonOrganismAnchorsV2: require('/app/components/organisms/common/OrganismAnchorsV2.vue').default})
